@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Bold/WorkSans-Bold.woff2') format('woff2'),
        url('/assets/fonts/Bold/WorkSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Thin/WorkSans-Thin.woff2') format('woff2'),
        url('/assets/fonts/Thin/WorkSans-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Semi-Bold/WorkSans-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Semi-Bold/WorkSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Black/WorkSans-Black.woff2') format('woff2'),
        url('/assets/fonts/Black/WorkSans-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Regular/WorkSans-Regular.woff2') format('woff2'),
        url('/assets/fonts/Regular/WorkSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('/assets/fonts/Italic/WorkSans-Italic.woff2') format('woff2'),
        url('/assets/fonts/Italic/WorkSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Varela Round';
    src: url('/assets/fonts/Regular/VarelaRound-Regular.woff2') format('woff2'),
        url('/assets/fonts/Regular/VarelaRound-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Black-Italic/Nunito-BlackItalic.woff2') format('woff2'),
        url('/assets/fonts/Balck-Italic/Nunito-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Black/Nunito-Black.woff2') format('woff2'),
        url('/assets/fonts/Balck/Nunito-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Bold/Nunito-Bold.woff2') format('woff2'),
        url('/assets/fonts/Bold/Nunito-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Extra-Light/Nunito-ExtraLight.woff2') format('woff2'),
        url('/assets/fonts/Extra-Light/Nunito-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Italic/Nunito-Italic.woff2') format('woff2'),
        url('/assets/fonts/Italic/Nunito-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Extra-Light-Italic/Nunito-ExtraLightItalic.woff2') format('woff2'),
        url('/assets/fonts/Extra-Light-Italic/Nunito-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Light-Italic/Nunito-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/Light-Italic/Nunito-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Light/Nunito-Light.woff2') format('woff2'),
        url('/assets/fonts/Light/Nunito-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Semi-Bold/Nunito-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Semi-Bold/Nunito-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Extra-Bold-Italic/Nunito-ExtraBoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Extra-Bold-Italic/Nunito-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Regular/Nunito-Regular.woff2') format('woff2'),
        url('/assets/fonts/Regular/Nunito-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Semi-Bold-Italic/Nunito-SemiBoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Semi-Bold-Italic/Nunito-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Extra-Bold/Nunito-ExtraBold.woff2') format('woff2'),
        url('/assets/fonts/Extra-Bold/Nunito-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Bold-Italic/Nunito-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Bold-Italic/Nunito-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

/**
* Barlow
**/
@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Black-Italic/BarlowCondensed-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Black/BarlowCondensed-Black.ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Bold/BarlowCondensed-Bold.ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Bold-Italic/BarlowCondensed-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Italic/BarlowCondensed-Italic.ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Extra-Light-Italic/BarlowCondensed-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Light-Italic/BarlowCondensed-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Light/BarlowCondensed-Light.ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Semi-Bold/BarlowCondensed-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Extra-Bold-Italic/BarlowCondensed-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Regular/BarlowCondensed-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Semi-Bold-Italic/BarlowCondensed-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow';
    src: url('/assets/fonts/Extra-Bold/BarlowCondensed-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Co';
    src: url('/assets/fonts/Regular/Co Headline.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Co';
    src: url('/assets/fonts/Semi-Bold-Italic/Co Headline Light.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Co';
    src: url('/assets/fonts/Bold/Co Headline Bold.otf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CoText';
    src: url('/assets/fonts/Regular/Co Text.woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CoText';
    src: url('/assets/fonts/Semi-Bold-Italic/Co Text Light.woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CoText';
    src: url('/assets/fonts/Bold/Co Text Bold.woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}






