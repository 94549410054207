// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "styles/spinner.scss";

// Include Swiper css
@import 'swiper/scss';
@import 'swiper/scss/navigation';
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

//----------------------------------*\
// Fonts
//----------------------------------*/
@import 'styles/modules/_fonts.scss';

@import url("https://use.typekit.net/hmt6hbq.css");

// primary font
$primary_font: 'Barlow';
// Header font
$header_font: 'co-headline';

$button_font: 'co-text';
// font-weight
$thin:100;
$light:300;
$regular:500;
$bold:700;
$black:900;
$button-font-weight: 400;
$nav-button-font-weight: 400;
// Font size
$font-xs:12px;
$font-sm: 14px;
$primary-fs: 17px;
$font-md: 18px;
$font-lg: 20px;
$h1-size: 42px;
$h2-size: 34px;
$h3-size: 26px;
$h4-size: 20px;
$h5-size: 18px;
$h6-size: 12px;
$h7-size: 12px;
$button-font-size: 17px;
$nav-button-font-size: 19px;
// Line Height
$body_lh: 1.8;
$p_lh: 24.12px;
$h1_lh: 1.16;
$h2_lh: 1.2;
$h3_lh: 28.6px;
$h4_lh: 1.36;
$h5_lh: 1.4;
$h6_lh: 0.8;
$h7_lh: 0.8;
$button-line-height: 17.68px;
$nav-button-line-height: 20.9px;

// Colors
$primary-light-orange : #ff965c;
$primary-orange : #f3723f;
$primary-dark-blue: #253d58;
$primary-blue : #2564a8;
$primary-light-blue: #377CC6;
$primary-light-beige : #fff5eb;
$primary-beige : #ffe3c8;
$primary-yellow : #ffe04d;
$primary-white : #ffffff;
$primary-black : #000000;
$button-font-color: $primary-light-beige;
$nav-button-font-color: $primary-blue;
$primary-overlay: #2564a888;


//$primary-beige : #fff5eb;
//$primary-orange : #f79b6f;
$primary-orange-50: #f79b6f80;
$primary-orange-80 : #f79b6fbb;

$primary-white-80 : #ffffffbb;
$primary-charcoal : #333333;
$primary-charcoal-50 : #33333360;

$primary-gray : #999999;
$primary-gray-50 : #9999997a;



// academy
$ac-border-color : #291D4D;
$ac-dialog-background: #FFE3B4;
$ac-menu-background: #FFE8D2;
$ac-menu-border-color: #A48465;
$ac-menu-font-color: #3A160D;
$ac-menu-selected-background: #7FC2FF;


html, body {
    width:100%;
    
    margin: 0;
    -ms-overflow-style: none;   /* IE and Edge */
    scrollbar-width: none;      /* Firefox */
}

body {
    font-family: $primary_font;
    font-size: $primary-fs;
    font-weight: $regular;
    line-height: $body_lh;
}

a {
    font-family: $header_font;
    font-size: $font-md;
    font-weight: $regular;
    color: $primary-beige;
    margin: 20px;
}

p { 
    font-family: $primary_font;
    font-size: $font-md;
    font-weight: $regular;
    line-height: $p_lh;
    margin-block-start: 0;
    margin-block-end: 0;
    color: $primary-dark-blue;
}

h1 {
    font-family: $header_font;
    font-size: $h1-size;
    font-weight: $regular;
    line-height: $h1_lh;
}

h2 {
    font-family: $header_font;
    font-size: $h2-size;
    font-weight: $regular;
    line-height: $h2_lh;
}

h3 {
    font-family: $header_font;
    font-size: $h3-size;
    font-weight: $regular;
    line-height: $h3_lh;
    margin-block-start: 0;
    margin-block-end: 0;
    color: $primary-blue;
    margin-bottom: -4px;
}

h4 {
    font-family: $header_font;
    font-size: $h4-size;
    font-weight: $regular;
    line-height: $h4_lh;
    color: $primary-blue;
}

h5 {
    font-family: $header_font;
    font-size: $h5-size;
    font-weight: $regular;
    line-height: $h5_lh;
}

h6 {
    font-family: $header_font;
    font-size: $h6-size;
    font-weight: $light;
    line-height: $h6_lh;
}

label {
    display: flex;
    align-items: center;
    gap: 10px;
}

// Input

input[type="text"], input[type="password"] {
    background-color: $primary-white;
    border-radius: 3px;
    padding: 21px 20px;
    font-family: $header-font;
    font-size: 18px;
    line-height: 24.12px;
    font-weight: 400;
    border: 1px solid $primary-beige;
    width: -webkit-fill-available;

    @media only screen and (max-width: 600px) {
        & {
            font-size: 14px;
            line-height: 17.62px;
            padding: 12px 10px;
        }
    }
}

input[type="text"]::placeholder {
    color: $primary-light-orange;
    opacity: 0.5;
}
input[type="text"]::-ms-input-placeholder {
    color: $primary-light-orange;
    opacity: 0.5;
}

input[type="checkbox"] {
    border: 2px solid $primary-blue;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    background-color: $primary-dark-blue;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:disabled {
    color: $primary-charcoal-50;
    cursor: not-allowed;
}

input[type="checkbox"]:focus {
    outline: none;
}


//Select

select {
    width: 100%;
    border-radius: 3px;
    font-size: 22px;
    font-family: $primary-font;
    line-height: 24px;
    font-weight: 500;
    color: $primary-blue;
    border-color: $primary-blue;
    padding: 16px 20px;
    appearance: none;
    background: $primary-white;
    background-image: url(/assets/base_icons/icon_arrow_down_blue.svg);
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
}



// div Definitions
.mat-toolbar-adjustment {
    position: sticky;
    position: -webkit-sticky; /* For macOS/iOS Safari */
    top: 0; /* Sets the sticky toolbar to be on top */
    flex: 0 1 160px;
    z-index: 1000; /* Ensure that your app's content doesn't overlap the toolbar */
    min-height:160px;
}

app-root {
    height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: stretch;
}

main {
    flex: 1 1 auto;
}

/**
* Buttons
**/
.modal-button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.autonome-button-orange {
    height: 30px;
    max-height: 44px;
    height: fit-content;
    padding: 6px 21px;
    gap: 10px;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background-color: $primary-orange;
    border: $primary-orange solid 2px;
    cursor: pointer;
    p {
        font-size: $button-font-size;
        line-height: $button-line-height;
        font-weight: $button-font-weight;
        color: $button-font-color;
        font-family: $button_font;
        letter-spacing: 1.3px;
        margin-block-start: 0;
        margin-block-end: 0;
        text-transform: uppercase;
        text-align: center;
    }
}
.autonome-button-orange:hover {
    background-color: $primary-light-orange;
}

.autonome-button-orange:active {
    opacity: 20%;
}

.autonome-button-orange:disabled {
    background-color: $primary-gray-50;
    border: $primary-gray solid 2px;
}

.autonome-button-white {
    height: 30px;
    padding: 6px 21px;
    gap: 10px;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background-color: $primary-white;
    border: $primary-orange solid 2px;
    cursor: pointer;
    p {
        font-size: $button-font-size;
        line-height: $button-line-height;
        font-weight: $button-font-weight;
        color: $primary-orange;
        font-family: $header_font;
        letter-spacing: 1.3px;
        margin-block-start: 0;
        margin-block-end: 0;
        text-transform: uppercase;
        margin-bottom: -4px;
        text-align: center;
    }
}
.autonome-button-white:hover {
    background-color: $primary-light-orange;
    color: $button-font-color;
}

.autonome-button-white:active {
    opacity: 20%;
}

.nav-button {
    p {
        font-size: $nav-button-font-size;
        line-height: $nav-button-line-height;
        font-weight: $nav-button-font-weight;
        color: $nav-button-font-color;
        font-family: $header_font;
        margin-block-start: 0;
        margin-block-end: 0;
        text-align: center;
        margin-bottom: -4px;
    }
}

.social-button-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
}

.social-login-button {
    background-color: $primary-white;
    border-color: $primary-charcoal-50;
    border-style: solid;
    border-radius: 25px;
    border-width: 1px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 6px;

    .google-login-svg {
        width: 16px;
        height: 16px;
    }
}



/**
* Empty divs and adjustments
**/
.empty-third-hdiv {
    flex: 1;
}

.overlay-background {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: $primary-overlay;
    z-index: 998;
    backdrop-filter: blur(4px);
}

.z-index-1500 {
    z-index: 1500;
}

/**
* Modals and boxes
**/
.modal-box {
    height: fit-content;
    align-items: unset;
    top: 27%;
}

.modal-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 30px 30px;
    gap: 18px;
}

.comment-box {
    height: fit-content;
    align-items: unset;
    top: 55px;
}

.comment-box:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 25px solid $primary-white;
    right: 47%;
    top: -35px;
    z-index: 1500;
   }

.mdc-style {
    overflow: unset;
    border-radius: 10px !important;
}

/**
* Warnings & Special Symbols
**/
.modal-warning {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    border: $primary-orange solid 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.recipes-container {
    width : 100%;
    height : 100%;
    display: flex;
    background-color: $primary-beige;
}

.modal-control-button {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin:6px;
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.modal-header {
    font-family: $header_font;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: center;
    color: $primary-charcoal
}

.modal-section-header {
    font-family: $header_font;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: center;
    margin: 16px 0;
    color: $primary-charcoal
}

.modal-text-button {
    font-family: $header_font;
    font-size: $h3-size;
    font-weight: $regular;
    line-height: $h3_lh;
    background-color:$primary-orange;
    color: $primary-beige;
    border-radius: 5px;
    border: none;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    width: fit-content;
    margin: 16px;
    flex: 1 1 0px
}

.clickable-text {
    background-color: #fff;
    border-style: solid;
    border-color: #333;
    border-width: 1px;
    border-radius: 5px;
    padding: 2px;
    margin-block-start: 0;
    margin-block-end: 0;
    width: fit-content;
    cursor: pointer;
    line-height: 1.2;
    font-size: 8pt;
}

.clickable-text-underlined {
    color: $primary-dark-blue;
    font-family: $header_font;
    font-size: 18px;
    line-height: 24.12px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}

.menu-text a{
    font-family: $header_font;
    font-size: $h3-size;
    font-weight: $regular;
    line-height: $h3_lh;
    color: $primary-beige;
}

.modal-text-button:disabled {
    background-color: #666666;
    cursor: none;
}

.right-button {
    margin-left:40px;
}

.modal-control-container {
    display: flex;
    justify-content: center;
}

.modal-form-fields {
    display: flex;
    flex-direction: column;
}

.modal-form-field {
    display: flex;
    justify-content: space-between;
}

.modal-controls-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.modal-input-field {
    background-color: $primary-white;
    border-color: $primary-orange-50;
    border-width: 1px;
    border-radius: 5px;
    border-style: solid;
    padding: 0.75em;
    font-size: 13pt;
    text-align: center;
}
.modal-input-field.ng-invalid {
    border-color: red;
}

.mat-dialog-container#modal-component {
    border-radius: 15px;
    background-color: $primary-beige;
}

.custom-dialog-container .mat-dialog-container {
    padding: 0;
}

.error-message {
    font-size: $font-xs;
    color: red;
}

.no-overflow {
    overflow-y: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $primary-white;
    border-color: $primary-orange;
    border-width: 2px;
    border-style: solid;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 3px;
    bottom: 3px;
    background-color: $primary-orange-50;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
    background-color: $primary-orange
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  @media only screen and (max-width: 425px) {

  }

.cdk-overlay-container {
    backdrop-filter: blur(4px);
}

.cdk-overlay-dark-backdrop {
    background: $primary-blue;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
}

.mat-dialog-container {
    border-radius: 10px;
}



/* Scene transitions */
.scene-transition {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background: #fff;
    opacity: 0;
    animation: scene-transition-fade-in 0.3s forwards;
}

.fade-out {
    animation: scene-transition-fade-out 0.3s forwards;
}

@keyframes scene-transition-fade-in {
    from { opacity:0; }
    to { opacity: 1; }
}
@keyframes scene-transition-fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

