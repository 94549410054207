#loader {
    bottom: 0;
    height: 175px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 175px;
  }
  
  #loader {
    bottom: 0;
    height: 175px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 175px;
  }
  
  #loader .dot {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 87.5px;
  }
  
  #loader .dot::before {
    border-radius: 100%;
    content: "";
    height: 87.5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(0);
    width: 87.5px;
  }
  
  #loader .dot:nth-child(1) {
    transform: rotate(45deg);
  }
  
  #loader .dot:nth-child(n+1)::before {
    animation: 0.8s linear 0.1s normal none infinite running load;
    background: #FFF5EC none repeat scroll 0 0;
  }
  
  #loader .dot:nth-child(2) {
    transform: rotate(90deg);
  }
  
  #loader .dot:nth-child(2)::before {
    animation: 0.8s linear 0.2s normal none infinite running load;
    background: #D1C8BF none repeat scroll 0 0;
  }
  
  #loader .dot:nth-child(3) {
    transform: rotate(135deg);
  }
  
  #loader .dot:nth-child(3)::before {
    animation: 0.8s linear 0.3s normal none infinite running load;
    background: #C8BEB6 none repeat scroll 0 0;
  }
  
  #loader .dot:nth-child(4) {
    transform: rotate(180deg);
  }
  
  #loader .dot:nth-child(4)::before {
    animation: 0.8s linear 0.4s normal none infinite running load;
    background: #938B83 none repeat scroll 0 0;
  }
  
  #loader .dot:nth-child(5) {
    transform: rotate(225deg);
  }
  
  #loader .dot:nth-child(5)::before {
    animation: 0.8s linear 0.5s normal none infinite running load;
    background: #8E857D none repeat scroll 0 0;
  }
  
  #loader .dot:nth-child(6) {
    transform: rotate(270deg);
  }
  
  #loader .dot:nth-child(6)::before {
    animation: 0.8s linear 0.6s normal none infinite running load;
    background: #776E66 none repeat scroll 0 0;
  }
  
  #loader .dot:nth-child(7) {
    transform: rotate(315deg);
  }
  
  #loader .dot:nth-child(7)::before {
    animation: 0.8s linear 0.7s normal none infinite running load;
    background: #625A53 none repeat scroll 0 0;
  }
  
  #loader .dot:nth-child(8) {
    transform: rotate(360deg);
  }
  
  #loader .dot:nth-child(8)::before {
    animation: 0.8s linear 0.8s normal none infinite running load;
    background: #352E27 none repeat scroll 0 0;
  }
  
  #loader .loading {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    bottom: -40px;
    height: 20px;
    left: 0;
    position: absolute;
    right: 0;
    width: 180px;
  }
  
  @keyframes load {
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
  
  @keyframes load {
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
  
  .spinner-message {
    text-align: center;
  }